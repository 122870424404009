@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap);
/*==================== GOOGLE FONTS ====================*/

/*==================== VARIABLES CSS ====================*/

:root {
  /* CSS VARIABLES FOR LATER US */
  --header-height: 3rem;

  /*========== Colors ==========*/
  /* Change favorite color */
  --hue-color: 210; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

  /* HSL color mode */
  --first-color: hsl(var(--hue-color), 69%, 61%);
  --first-color-second: hsl(var(--hue-color), 69%, 61%);
  --first-color-alt: hsl(var(--hue-color), 57%, 53%);
  --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
  --first-color-lighter2: hsl(var(--hue-color), 60%, 88%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 45%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  --input-color: hsl(var(--hue-color), 70%, 96%);
  --body-color: hsl(var(--hue-color), 60%, 99%);
  --container-color: #fff;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;
  /* --body-font: "Ubuntu", sans-serif; */

  /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*========== Variables Dark theme ==========*/

body.dark-theme {
  /* HSL color mode */
  --first-color-second: hsl(var(--hue-color), 30%, 8%);
  --title-color: hsl(var(--hue-color), 8%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 28%, 12%);
  --container-color: hsl(var(--hue-color), 29%, 16%);

  --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}

/*==================== BASE ====================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  /*top right bottom left*/
  margin: 0 0 3rem 0;
  margin: 0 0 var(--header-height) 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  background-color: hsl(210, 60%, 99%);
  background-color: var(--body-color);
  color: hsl(210, 8%, 45%);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  color: hsl(210, 8%, 15%);
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*==================== LAYOUT ====================*/

.container {
  max-width: 768px;
  margin-left: 1.5rem;
  margin-left: var(--mb-1-5);
  margin-right: 1.5rem;
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

/*==================== REUSABLE CSS CLASSES ====================*/

.section {
  padding: 2rem 0 4rem;
}

.section__title {
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
}

.section__subtitle {
  display: block;
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*==================== BUTTONS ====================*/

.button {
  display: inline-block;
  background-color: hsl(210, 69%, 61%);
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  font-weight: var(--font-medium);
}

.button2 {
  display: inline-block;
  background-color: hsl(210, 69%, 61%);
  background-color: var(--first-color);
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  font-weight: var(--font-medium);
  font-size: 0.75rem;
  font-size: var(--smaller-font-size);
  margin-top: 1rem;
  margin-top: var(--mb-1);
  width: 100%;
}

.button:hover {
  background-color: hsl(210, 57%, 53%);
  background-color: var(--first-color-alt);
}

.button__icon {
  font-size: 1.25rem;
  margin-left: 0.25rem;
  margin-left: var(--mb-0-25);
  transition: 0.3s;
}

.button--white {
  background-color: #fff;
  color: hsl(210, 69%, 61%);
  color: var(--first-color);
}

.button--white:hover {
  background-color: #fff;
}

.button--flex {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
}

.button--flex2 {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.button--small {
  padding: 0.75rem 1rem;
}

.button--link {
  padding: 0;
  background-color: transparent;
  color: hsl(210, 69%, 61%);
  color: var(--first-color);
}

.button--link:hover {
  background-color: transparent;
  color: hsl(210, 57%, 53%);
  color: var(--first-color-alt);
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: hsl(210, 12%, 90%);
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(210, 12%, 80%);
  background-color: var(--scroll-thumb-color);
  border-radius: 0.6rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(210, 8%, 65%);
  background-color: var(--text-color-light);
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px) {
  .container {
    margin-left: 1rem;
    margin-left: var(--mb-1);
    margin-right: 1rem;
    margin-right: var(--mb-1);
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  /* .container {
    margin-right: auto;
    margin-left: auto;
  } */

  body {
    margin: 0;
  }

  .section {
    padding: 3rem 0 6rem;
  }

  .section__subtitle {
    margin-bottom: 4rem;
  }

  .main {
    padding: 0 1rem !important;
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) {
  .main {
    padding: 0;
  }
} */

/*==================== IMPORT PROJECTS CSS FILES ====================*/

/*========== Button Dark/Light ==========*/
.nav__btns {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.change-theme {
  font-size: 1.25rem;
  color: var(--title-color);
  margin-right: var(--mb-1);
  cursor: pointer;
}

.change-theme:hover {
  color: var(--first-color);
}

/*==================== LAYOUT ====================*/
/* Responsible for keeping the navbar fixed at the bottom */
.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

/*==================== NAV ====================*/
.nav {
  max-width: 968px;
  height: var(--header-height);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /*background-color: black;*/
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__logo:hover {
  color: var(--first-color);
}

.nav__toggle {
  font-size: 1.1rem;
  cursor: pointer;
}

.nav__toggle:hover {
  color: var(--first-color);
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 3s;
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.nav__link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  /* font-size: var(--smaller-font-size); */
  font-size: 0.7rem;
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__icon {
  font-size: 1.4rem;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--first-color);
}

.nav__close:hover {
  color: var(--first-color-alt);
}

/* show menu */
.show-menu {
  bottom: 0;
}

/* Active link */
.active-link {
  color: var(--first-color);
}

/* Change background header */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px) {
  .nav__list {
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }
}

/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .nav__link {
    font-size: var(--smaller-font-size);
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .header {
    top: 0;
    bottom: auto;
    bottom: initial;
    padding: 0 1rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    max-width: 100%;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }

  .nav__list {
    display: -webkit-flex;
    display: flex;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .nav__menu {
    /* The auto keyword will give the left side a share of the remaining space. */
    /* margin-left: auto; */
    margin: 0;
  }

  .change-theme {
    margin: 0;
  }
}

/* For large devices
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) { */
/* Not used */
/* .header {
    padding: 0 !important;
  } */

/* .nav__menu {
    margin: 0;
  }

  .nav {
    max-width: 100%;
  } */
/* } */

/*==================== IMPORT PROJECTS CSS FILES ====================*/

/*==================== HOME ====================*/
.home__container {
  /* background-color: rgb(242, 228, 150); */
  grid-gap: 1rem;
  gap: 1rem;
}

.home__content {
  /* background-color: pink; */
  grid-template-columns: 0.5fr 3fr;
  padding-top: 3.5rem;
  -webkit-align-items: center;
          align-items: center;
}

.home__social {
  /* background-color: yellow; */
  display: grid;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--first-color);
}

.home__social-icon:hover {
  color: var(--first-color-alt);
}

.home__blob {
  width: 200px;
  fill: var(--first-color);
}

.home__blob-img {
  width: 170px;
}

.home__data {
  /* background-color: blue; */
  grid-column: 1 / 3;
}

.home__title {
  font-size: var(--big-font-size);
}

.home__subtitle {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-75);
}

.home__description {
  margin-bottom: var(--mb-2);
}

.home__scroll {
  display: none;
}

.home__bulb-icon {
  font-size: 1.25rem;
  color: var(--first-color);
  margin-left: 0.3rem;
}

.home__scroll-button {
  color: var(--first-color);
  transition: 0.3s;
}

.home__scroll-button:hover {
  -webkit-transform: translateY(0.25rem);
          transform: translateY(0.25rem);
}

.home__scroll-mouse {
  font-size: 2rem;
}

.home__scroll-name {
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
}

.home__scroll-arrow {
  font-size: 1.25rem;
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px) {
  .home__content {
    grid-template-columns: 0.25fr 3fr;
  }

  .home__blob {
    /* margin-left: var(--mb-2); */
    width: 180px;
  }
}
/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .home__content {
    grid-template-columns: -webkit-max-content 1fr 1fr;
    grid-template-columns: max-content 1fr 1fr;
  }

  .home__data {
    grid-column: initial;
    margin-left: var(--mb-0-75);
  }

  .home__img {
    -webkit-order: 1;
            order: 1;
    justify-self: center;
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .home__container {
    grid-row-gap: 5rem;
    row-gap: 5rem;
    margin: auto;
  }

  .home__content {
    padding-top: 5.5rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .home__blob {
    width: 240px;
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
@media screen and (min-width: 1024px) {
  /* .home__container {
    margin: auto;
  } */

  .home__blob {
    width: 320px;
  }

  .home__social {
    -webkit-transform: translateX(-4rem);
            transform: translateX(-4rem);
  }
}

/*==================== IMPORT PROJECTS CSS FILES ====================*/

/*==================== ABOUT ====================*/
/* .about {
  background-color: lemonchiffon;
} */

/* .about__container {
  background-color: salmon;
} */

.about__img {
  width: 200px;
  border-radius: 0.5rem;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}

/* .about__data {
    background-color: sandybrown;
  } */

.about__description {
  text-align: center;
  margin-bottom: var(--mb-1-5);
  font-size: var(--small-font-size);
}

.about__description-link {
  display: inline;
  color: var(--first-color);
}

.about__description-link:hover {
  color: var(--first-color-alt);
}

/*==================== MEDIA QUERIES ====================*/
/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .about__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .about__img {
    -webkit-order: 2;
            order: 2;
    width: auto;
  }

  .about__data {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: var(--mb-0-5);
    margin-bottom: var(--mb-0-5);
  }

  .about__description {
    /* display: flex; */
    /* align-items: center; */
    margin-bottom: 0;
  }
}

@media screen and (min-width: 600px) {
  .about__data {
    margin-top: var(--mb-2-5);
    margin-bottom: var(--mb-2-5);
  }
}

@media screen and (min-width: 696px) {
  .about__data {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .about__container {
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
    margin: auto;
  }

  .about__data {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  /* .about__description {
    margin-bottom: 4rem;
  } */
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) {
  .about__container {
    margin: auto;
  }

  .about__data {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
} */

/*==================== IMPORT PROJECTS CSS FILES ====================*/

/*==================== QUALIFICATION ====================*/
/* .qualification {
  background-color: lemonchiffon;
} */

.qualification__tabs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: var(--mb-2);
}

.qualification__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  cursor: pointer;
}

/* #qualification-edu {
  margin-left: var(--mb-1);
}

#qualification-work {
  margin-right: var(--mb-1);
} */

.qualification__button:hover {
  color: var(--first-color);
}

.qualification__icon {
  /* font size actually increases the size of the icon */
  font-size: 1.8em;
  margin-right: var(--mb-0-25);
}

/******* ANOTHER DIV **********/

.qualification__data {
  display: grid;
  grid-template-columns: 1fr -webkit-max-content 1fr;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.qualification__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.qualification__subtitle {
  display: inline-block;
  font-size: var(--smaller-font-size);
  margin-bottom: var(--mb-0-5);
}

.qualification__calendar {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
}

.uil-calendar-alt {
  margin-right: var(--mb-0-25);
}

.qualification__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--first-color);
  border-radius: 50%;
}

.qualification__rounder-2 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--first-color);
  border-radius: 50%;
}

.qualification__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--first-color);
  -webkit-transform: translate(6px, -7px);
          transform: translate(6px, -7px);
}

.qualification__line-2 {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--first-color);
  -webkit-transform: translate(4px, -7px);
          transform: translate(4px, -7px);
}

.qualification [data-content] {
  display: none;
}

.qualification__active[data-content] {
  display: block;
}

.qualification__button.qualification__active {
  color: var(--first-color);
}

/*==================== MEDIA QUERIES ====================*/
/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .qualification__tabs {
    padding-left: var(--mb-3);
    padding-right: var(--mb-3);
  }

  .qualification__sections {
    display: grid;
    grid-template-columns: 0.6fr;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .qualification__container {
    margin: auto;
  }

  .qualification__tabs {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .qualification__button {
    margin: 0 var(--mb-3);
  }

  .qualification__sections {
    grid-template-columns: 0.5fr;
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) {
  .qualification__container {
    margin: auto;
  }
} */

/*==================== IMPORT PROJECTS CSS FILES ====================*/

/*==================== PROJECTS====================*/
/* .projects {
  background-color: rgb(253, 245, 170);
} */

.projects__container {
  /* background-color: pink; */
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.projects__content {
  position: relative;
  background-color: var(--container-color);
  padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.projects__content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* For i elements */
.projects__icon {
  display: block;
  font-size: 1.5rem;
  color: var(--first-color);
  margin-bottom: var(--mb-1);
}

/* For svg elements */
.projects__icon2 {
  /* background-color: rgb(153, 153, 51); */
  display: block;
  fill: var(--first-color);
  margin-bottom: var(--mb-0-5);
}
.projects__icon3 {
  /* background-color: rgb(153, 153, 51); */
  display: block;
  font-size: 1.5rem;
  stroke: var(--first-color);
  stroke-width: 0.15em;
  margin-bottom: var(--mb-0-5);
}

.projects__title {
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}

.projects__button {
  cursor: pointer;
  font-size: var(--smaller-font-size);
}

.projects__button-arrow {
  margin-right: 1rem;
}

.projects__button:hover .button__icon {
  -webkit-transform: translateX(0.25rem);
          transform: translateX(0.25rem);
}

.projects__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);

  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.projects__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.projects__modal-projects {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.projects__modal-project {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.projects__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  /* margin-bottom: var(--mb-1-5); */
}

.projects__modal-date {
  font-size: var(--smaller-font-size);
  margin-bottom: var(--mb-0-75);
}

.projects__modal-close {
  position: absolute;
  top: 1.2rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.projects__modal-link {
  position: absolute;
  top: 1.2rem;
  right: 3rem;
  font-size: 1.3rem;
  margin-top: 0.2rem;
  color: var(--first-color);
  cursor: pointer;
}

/* .projects__modal-icon {
  color: var(--first-color);
  margin-right: var(--mb-0-25);
} */

.projects__modal-project-objective,
.projects__modal-project-position,
.projects__modal-project-partners {
  margin-bottom: var(--mb-0-5);
  font-weight: var(--font-semi-bold);
}

.projects__modal-project-objective-icon,
.projects__modal-project-position-icon,
.projects__modal-project-partners-icon {
  margin-right: var(--mb-0-25);
  font-size: var(--h3-font-size);
}

.projects__modal-project-objective-text,
.projects__modal-project-position-text {
  font-size: 0.7rem;
}

.projects__modal-project-partners-list {
  display: grid;
  grid-row-gap: 0.4rem;
  row-gap: 0.4rem;
}

.projects__modal-project-partners-list-element-text {
  font-size: 0.6rem;
}

/* .projects__modal-project-objective-font {
  font-size: 0.6rem;
  margin-top: var(--mb-0-25);
} */

/* Active Modal */

.active-modal {
  opacity: 1;
  visibility: visible;
}

/*==================== MEDIA QUERIES ====================*/
/* For medium devices */
/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .projects__container {
    /* grid-template-columns: repeat(3, 218px); */
    /* justify-content: center; */
    margin: auto;
  }

  .projects__content {
    padding: 5rem 0 2rem 2.5rem;
  }

  .projects__icon2,
  .projects__icon3 {
    width: 105px !important;
    height: 105px !important;
  }

  .projects__modal-content {
    width: 450px;
  }

  .projects__modal-project-objective-text,
  .projects__modal-project-position-text {
    font-size: var(--small-font-size);
  }

  .projects__modal-project-partners-list-element-text {
    font-size: var(--smaller-font-size);
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) {
  .projects__container {
    margin: auto;
  }
} */

/*==================== IMPORT PROJECTS CSS FILES ====================*/

/*==================== PUBLICATIONS ====================*/

/* .testimonial {
  background-color: lemonchiffon;
} */

/* .publications__content {
  display: grid;
  grid-template-rows: 30% 10% 20% 25% 15%;
  background-color: rebeccapurple;
  height: auto !important;
} */

.publications__data,
.publications__header {
  display: -webkit-flex;
  display: flex;
}

.publications__data {
  /* background-color: rebeccapurple; */
  /* justify-content: space-between; */
  /* align-items: center; */
  margin-top: var(--mb-2-5);
  margin-bottom: var(--mb-0-5);
}

.publications__header {
  -webkit-align-items: center;
          align-items: center;
}

.publications__img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: var(--mb-0-75);
}

.publications__name {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.publications__icon-link {
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
  margin-left: 1rem;
}

.publications__type {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
  margin-bottom: var(--mb-0-5);
}

.publications__info-author {
  /* background-color: purple; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: var(--mb-0-5);
}
.publications__author {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
}
.publications__author-text {
  font-size: var(--smaller-font-size);
}

.publicications__info-keywords {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: var(--mb-0-5);
}
.publications__keywords {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
}
.publications__keywords-text {
  font-size: var(--smaller-font-size);
}

.publications__description {
  margin-bottom: var(--mb-2-5);
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 97% !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--first-color) !important;
}

/*==================== MEDIA QUERIES ====================*/
/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .publications__content {
    display: grid;
    grid-template-rows: 30% 10% 20% 25% 15%;
    height: auto !important;
  }
}

/* For medium devices */
/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .publications__container {
    margin: auto;
  }
}
/* For large devices */
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) {
  .publications__container {
    margin: auto;
  }
} */

/*==================== IMPORT PROJECTS CSS FILES ====================*/

/*==================== activities ====================*/
/* .activities {
  background-color: rgb(253, 245, 170);
} */

.activities__container {
  grid-row-gap: 0;
  row-gap: 0;
}

.activities__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* margin-bottom: var(--mb-1); */
  cursor: pointer;

  /* border-style: solid;
  border-radius: 0.5rem;
  border-color: var(--first-color); */

  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  border-left-style: solid;
  border-left-color: var(--first-color);
  border-right-style: solid;
  border-right-color: var(--first-color);
  border-top-style: solid;
  border-top-color: var(--first-color);
}

.activities__close > .activities__header {
  margin-bottom: var(--mb-1);

  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  border-bottom-style: solid;
  border-bottom-color: var(--first-color);
}

.activities__icon,
.activities__arrow {
  font-size: 2rem;
  color: var(--first-color);
  margin-left: var(--mb-1);
}

.activities__icon {
  margin-right: var(--mb-0-75);
}

.activities__title {
  font-size: var(--h3-font-size);
}

.activities__subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.activities__arrow {
  margin-left: auto;
  transition: 0.4s;
}

.activities__list {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  /* background-color: var(--first-color-lighter); */
  background-color: var(--container-color);

  /* border-style: solid;
  border-radius: 0.5rem;
  border-color: var(--first-color); */

  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  border-top-style: solid;
  border-top-color: var(--first-color);
  border-left-style: solid;
  border-left-color: var(--first-color);
  border-right-style: solid;
  border-right-color: var(--first-color);
  border-bottom-style: solid;
  border-bottom-color: var(--first-color);
}

.activities__data-initial {
  margin-top: var(--mb-1-5);
}

.activities__data-last {
  margin-bottom: var(--mb-1-5);
}

.activities__info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: var(--mb-0-5);
}

.activities__info-title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.activities__info-text {
  font-size: var(--small-font-size);
  /* font-weight: var(--font-medium); */
}

.activities__info-date {
  margin-top: var(--mb-1);
  font-size: var(--smaller-font-size);
  /* font-weight: var(--font-medium); */
}

.activities__titles {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: var(--mb-0-5);
}

.activities__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.activities__separator {
  border-top: 2px dotted var(--first-color);
}

.activities__bar,
.activities__percentage {
  height: 5px;
  border-radius: 0.25rem;
}

.activities__bar {
  background-color: var(--first-color-lighter);
}

.activities__percentage {
  display: block;
  background-color: var(--first-color);
}

/* DESCENDANT SELECTOR */
/* Select all elements that are descendants of a specified parent. */
.activities__close .activities__list {
  display: none;
  /* height: 0; */
  overflow: hidden;
}

.activities__open .activities__list {
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: var(--mb-2-5);
}

.activities__open .activities__arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px) {
  .activities__title {
    font-size: var(--normal-font-size);
  }
}

/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .activities__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .activities__title {
    font-size: var(--normal-font-size);
  }
}

/* For medium devices */
/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .activities__container {
    margin: auto;
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) {
  .activities__container {
    margin: auto;
  }
} */

/*==================== IMPORT PROJECTS CSS FILES ====================*/

/*==================== FOOTER ====================*/
.footer {
  /* background-color: lemonchiffon; */
  padding-top: 2rem;
}

.footer__container {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.footer__bg {
  background-color: var(--first-color-second);
  padding: 2rem 0 3rem;
}

.footer__title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-0-25);
}

.footer__subtitle {
  font-size: var(--small-font-size);
  display: block;
}

.footer-icon-bulb {
  font-size: var(--normal-font-size);
  margin-left: var(--mb-0-25);
}

.footer__mail {
  margin-top: var(--mb-1);
  font-size: var(--small-font-size);
}

.footer__socials {
  /* margin-top: var(--mb-0-5); */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.footer__social {
  font-size: 1.25rem;
  margin-right: var(--mb-1-5);
}

.footer__social:hover {
  color: var(--first-color-lighter);
}

.footer__robot {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.footer__icon {
  /* display: block; */
  width: 150px;
  height: 150px;
  /* font-size: 1.5rem; */
  /* stroke: #fff !important; */
  /* stroke-width: 0.26em; */
  margin-bottom: var(--mb-0-5);
}

.footer__copy {
  font-size: var(--smaller-font-size);
  text-align: center;
  color: var(--text-color-light);
  margin-top: var(--mb-2);
}

.footer__title,
.footer__subtitle,
.footer__social,
.footer__mail {
  color: #fff;
}

/*==================== MEDIA QUERIES ====================*/
/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer__socials {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .footer__robot {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .footer__container {
    padding: 0 1rem;
    max-width: 100%;
  }

  .footer__bg {
    padding: 3rem 0 3.5rem;
  }

  .footer__title {
    margin-bottom: var(--mb-0-25);
  }

  .footer__mail {
    margin-top: var(--mb-1-5);
  }

  .footer__socials {
    justify-self: start;
  }

  .footer__icon {
    width: 180px !important;
    height: 180px !important;
  }

  .footer__copy {
    margin-top: 3.5rem;
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
@media screen and (min-width: 1024px) {
  /* .footer__container {
    max-width: 100%;
  } */
  .footer__socials {
    margin-top: var(--mb-1);
  }

  .footer__robot {
    margin-right: var(--mb-3);
    justify-self: end;
  }

  .footer__icon {
    width: 200px !important;
    height: 200px !important;
  }
}

