/*==================== IMPORT PROJECTS CSS FILES ====================*/
@import "../../App.css";

/*==================== FOOTER ====================*/
.footer {
  /* background-color: lemonchiffon; */
  padding-top: 2rem;
}

.footer__container {
  row-gap: 1.5rem;
}

.footer__bg {
  background-color: var(--first-color-second);
  padding: 2rem 0 3rem;
}

.footer__title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-0-25);
}

.footer__subtitle {
  font-size: var(--small-font-size);
  display: block;
}

.footer-icon-bulb {
  font-size: var(--normal-font-size);
  margin-left: var(--mb-0-25);
}

.footer__mail {
  margin-top: var(--mb-1);
  font-size: var(--small-font-size);
}

.footer__socials {
  /* margin-top: var(--mb-0-5); */
  display: flex;
  justify-content: center;
}

.footer__social {
  font-size: 1.25rem;
  margin-right: var(--mb-1-5);
}

.footer__social:hover {
  color: var(--first-color-lighter);
}

.footer__robot {
  display: flex;
  justify-content: center;
}

.footer__icon {
  /* display: block; */
  width: 150px;
  height: 150px;
  /* font-size: 1.5rem; */
  /* stroke: #fff !important; */
  /* stroke-width: 0.26em; */
  margin-bottom: var(--mb-0-5);
}

.footer__copy {
  font-size: var(--smaller-font-size);
  text-align: center;
  color: var(--text-color-light);
  margin-top: var(--mb-2);
}

.footer__title,
.footer__subtitle,
.footer__social,
.footer__mail {
  color: #fff;
}

/*==================== MEDIA QUERIES ====================*/
/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer__socials {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .footer__robot {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .footer__container {
    padding: 0 1rem;
    max-width: 100%;
  }

  .footer__bg {
    padding: 3rem 0 3.5rem;
  }

  .footer__title {
    margin-bottom: var(--mb-0-25);
  }

  .footer__mail {
    margin-top: var(--mb-1-5);
  }

  .footer__socials {
    justify-self: start;
  }

  .footer__icon {
    width: 180px !important;
    height: 180px !important;
  }

  .footer__copy {
    margin-top: 3.5rem;
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
@media screen and (min-width: 1024px) {
  /* .footer__container {
    max-width: 100%;
  } */
  .footer__socials {
    margin-top: var(--mb-1);
  }

  .footer__robot {
    margin-right: var(--mb-3);
    justify-self: end;
  }

  .footer__icon {
    width: 200px !important;
    height: 200px !important;
  }
}
