/*==================== IMPORT PROJECTS CSS FILES ====================*/
@import "../../../App.css";

/*==================== HOME ====================*/
.home__container {
  /* background-color: rgb(242, 228, 150); */
  gap: 1rem;
}

.home__content {
  /* background-color: pink; */
  grid-template-columns: 0.5fr 3fr;
  padding-top: 3.5rem;
  align-items: center;
}

.home__social {
  /* background-color: yellow; */
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--first-color);
}

.home__social-icon:hover {
  color: var(--first-color-alt);
}

.home__blob {
  width: 200px;
  fill: var(--first-color);
}

.home__blob-img {
  width: 170px;
}

.home__data {
  /* background-color: blue; */
  grid-column: 1 / 3;
}

.home__title {
  font-size: var(--big-font-size);
}

.home__subtitle {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-75);
}

.home__description {
  margin-bottom: var(--mb-2);
}

.home__scroll {
  display: none;
}

.home__bulb-icon {
  font-size: 1.25rem;
  color: var(--first-color);
  margin-left: 0.3rem;
}

.home__scroll-button {
  color: var(--first-color);
  transition: 0.3s;
}

.home__scroll-button:hover {
  transform: translateY(0.25rem);
}

.home__scroll-mouse {
  font-size: 2rem;
}

.home__scroll-name {
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
}

.home__scroll-arrow {
  font-size: 1.25rem;
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px) {
  .home__content {
    grid-template-columns: 0.25fr 3fr;
  }

  .home__blob {
    /* margin-left: var(--mb-2); */
    width: 180px;
  }
}
/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .home__content {
    grid-template-columns: max-content 1fr 1fr;
  }

  .home__data {
    grid-column: initial;
    margin-left: var(--mb-0-75);
  }

  .home__img {
    order: 1;
    justify-self: center;
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .home__container {
    row-gap: 5rem;
    margin: auto;
  }

  .home__content {
    padding-top: 5.5rem;
    column-gap: 2rem;
  }

  .home__blob {
    width: 240px;
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
@media screen and (min-width: 1024px) {
  /* .home__container {
    margin: auto;
  } */

  .home__blob {
    width: 320px;
  }

  .home__social {
    transform: translateX(-4rem);
  }
}
