/*==================== IMPORT PROJECTS CSS FILES ====================*/
@import "../../../App.css";

/*==================== PROJECTS====================*/
/* .projects {
  background-color: rgb(253, 245, 170);
} */

.projects__container {
  /* background-color: pink; */
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.projects__content {
  position: relative;
  background-color: var(--container-color);
  padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.projects__content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* For i elements */
.projects__icon {
  display: block;
  font-size: 1.5rem;
  color: var(--first-color);
  margin-bottom: var(--mb-1);
}

/* For svg elements */
.projects__icon2 {
  /* background-color: rgb(153, 153, 51); */
  display: block;
  fill: var(--first-color);
  margin-bottom: var(--mb-0-5);
}
.projects__icon3 {
  /* background-color: rgb(153, 153, 51); */
  display: block;
  font-size: 1.5rem;
  stroke: var(--first-color);
  stroke-width: 0.15em;
  margin-bottom: var(--mb-0-5);
}

.projects__title {
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}

.projects__button {
  cursor: pointer;
  font-size: var(--smaller-font-size);
}

.projects__button-arrow {
  margin-right: 1rem;
}

.projects__button:hover .button__icon {
  transform: translateX(0.25rem);
}

.projects__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);

  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.projects__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.projects__modal-projects {
  row-gap: 1rem;
}

.projects__modal-project {
  display: flex;
  flex-direction: column;
}

.projects__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  /* margin-bottom: var(--mb-1-5); */
}

.projects__modal-date {
  font-size: var(--smaller-font-size);
  margin-bottom: var(--mb-0-75);
}

.projects__modal-close {
  position: absolute;
  top: 1.2rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.projects__modal-link {
  position: absolute;
  top: 1.2rem;
  right: 3rem;
  font-size: 1.3rem;
  margin-top: 0.2rem;
  color: var(--first-color);
  cursor: pointer;
}

/* .projects__modal-icon {
  color: var(--first-color);
  margin-right: var(--mb-0-25);
} */

.projects__modal-project-objective,
.projects__modal-project-position,
.projects__modal-project-partners {
  margin-bottom: var(--mb-0-5);
  font-weight: var(--font-semi-bold);
}

.projects__modal-project-objective-icon,
.projects__modal-project-position-icon,
.projects__modal-project-partners-icon {
  margin-right: var(--mb-0-25);
  font-size: var(--h3-font-size);
}

.projects__modal-project-objective-text,
.projects__modal-project-position-text {
  font-size: 0.7rem;
}

.projects__modal-project-partners-list {
  display: grid;
  row-gap: 0.4rem;
}

.projects__modal-project-partners-list-element-text {
  font-size: 0.6rem;
}

/* .projects__modal-project-objective-font {
  font-size: 0.6rem;
  margin-top: var(--mb-0-25);
} */

/* Active Modal */

.active-modal {
  opacity: 1;
  visibility: visible;
}

/*==================== MEDIA QUERIES ====================*/
/* For medium devices */
/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .projects__container {
    /* grid-template-columns: repeat(3, 218px); */
    /* justify-content: center; */
    margin: auto;
  }

  .projects__content {
    padding: 5rem 0 2rem 2.5rem;
  }

  .projects__icon2,
  .projects__icon3 {
    width: 105px !important;
    height: 105px !important;
  }

  .projects__modal-content {
    width: 450px;
  }

  .projects__modal-project-objective-text,
  .projects__modal-project-position-text {
    font-size: var(--small-font-size);
  }

  .projects__modal-project-partners-list-element-text {
    font-size: var(--smaller-font-size);
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) {
  .projects__container {
    margin: auto;
  }
} */
