/*==================== IMPORT PROJECTS CSS FILES ====================*/
@import "../../../App.css";

/*==================== QUALIFICATION ====================*/
/* .qualification {
  background-color: lemonchiffon;
} */

.qualification__tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--mb-2);
}

.qualification__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  cursor: pointer;
}

/* #qualification-edu {
  margin-left: var(--mb-1);
}

#qualification-work {
  margin-right: var(--mb-1);
} */

.qualification__button:hover {
  color: var(--first-color);
}

.qualification__icon {
  /* font size actually increases the size of the icon */
  font-size: 1.8em;
  margin-right: var(--mb-0-25);
}

/******* ANOTHER DIV **********/

.qualification__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.qualification__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.qualification__subtitle {
  display: inline-block;
  font-size: var(--smaller-font-size);
  margin-bottom: var(--mb-0-5);
}

.qualification__calendar {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
}

.uil-calendar-alt {
  margin-right: var(--mb-0-25);
}

.qualification__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--first-color);
  border-radius: 50%;
}

.qualification__rounder-2 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--first-color);
  border-radius: 50%;
}

.qualification__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--first-color);
  transform: translate(6px, -7px);
}

.qualification__line-2 {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--first-color);
  transform: translate(4px, -7px);
}

.qualification [data-content] {
  display: none;
}

.qualification__active[data-content] {
  display: block;
}

.qualification__button.qualification__active {
  color: var(--first-color);
}

/*==================== MEDIA QUERIES ====================*/
/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .qualification__tabs {
    padding-left: var(--mb-3);
    padding-right: var(--mb-3);
  }

  .qualification__sections {
    display: grid;
    grid-template-columns: 0.6fr;
    justify-content: center;
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .qualification__container {
    margin: auto;
  }

  .qualification__tabs {
    justify-content: center;
  }

  .qualification__button {
    margin: 0 var(--mb-3);
  }

  .qualification__sections {
    grid-template-columns: 0.5fr;
  }
}

/* For large devices */
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) {
  .qualification__container {
    margin: auto;
  }
} */
