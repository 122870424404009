/*==================== IMPORT PROJECTS CSS FILES ====================*/
@import "../../App.css";

/*========== Button Dark/Light ==========*/
.nav__btns {
  display: flex;
  align-items: center;
}

.change-theme {
  font-size: 1.25rem;
  color: var(--title-color);
  margin-right: var(--mb-1);
  cursor: pointer;
}

.change-theme:hover {
  color: var(--first-color);
}

/*==================== LAYOUT ====================*/
/* Responsible for keeping the navbar fixed at the bottom */
.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

/*==================== NAV ====================*/
.nav {
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*background-color: black;*/
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__logo:hover {
  color: var(--first-color);
}

.nav__toggle {
  font-size: 1.1rem;
  cursor: pointer;
}

.nav__toggle:hover {
  color: var(--first-color);
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 3s;
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 0.8rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: var(--smaller-font-size); */
  font-size: 0.7rem;
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__icon {
  font-size: 1.4rem;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--first-color);
}

.nav__close:hover {
  color: var(--first-color-alt);
}

/* show menu */
.show-menu {
  bottom: 0;
}

/* Active link */
.active-link {
  color: var(--first-color);
}

/* Change background header */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px) {
  .nav__list {
    column-gap: 0;
  }
}

/* For medium devices */
/**** width: 582px, height: 658px *****/
@media screen and (min-width: 568px) {
  .nav__link {
    font-size: var(--smaller-font-size);
  }
}

/**** width: 768px, height: 658px *****/
@media screen and (min-width: 768px) {
  .header {
    top: 0;
    bottom: initial;
    padding: 0 1rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    column-gap: 1rem;
    max-width: 100%;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }

  .nav__list {
    display: flex;
    column-gap: 2rem;
  }

  .nav__menu {
    /* The auto keyword will give the left side a share of the remaining space. */
    /* margin-left: auto; */
    margin: 0;
  }

  .change-theme {
    margin: 0;
  }
}

/* For large devices
/**** width: 1024px, height: 658px *****/
/* @media screen and (min-width: 1024px) { */
/* Not used */
/* .header {
    padding: 0 !important;
  } */

/* .nav__menu {
    margin: 0;
  }

  .nav {
    max-width: 100%;
  } */
/* } */
